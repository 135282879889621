import React from "react"
import VideoPage from "../../components/VideoPage"

const data = [
  {
    name: "Mediscene 2016: Mesa",
    link: "https://www.youtube.com/embed/WML5zmjtsmo",
  },
  {
    name: "Mediscene 2017: Hero – Demonyo Man May Langit Din",
    link: "https://www.youtube.com/embed/AMC2e2dOnLg",
  },
  {
    name: "Mediscene 2018: PI at iba pang mura sa mundo",
    link: "https://www.youtube.com/embed/uBKRqgeFMY8",
  },
  {
    name: "Mediscene 2019: Alamat at Mito",
    link: "https://www.youtube.com/embed/h1aKEIF83aU",
  },
]

const MediscenePage = () => <VideoPage videos={data} title="Mediscene" />

export default MediscenePage
